import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import Dashboard from "./main/DashBoard";
import Error404 from './main/Error404';

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/link/dev-app/:id?" element={<Dashboard />} />
      {/* 위에 정의되지 않은 모든 경로에 대해 /404 페이지로 리다이렉트 */}
      <Route path="*"  element={<Error404 />} />
    </Routes>
  </Router>
  );
}

export default App;
