import React from 'react';
import Header from '../structure/Header';
import Footer from '../structure/Footer';
import './DashBoard.css';
import { useTranslation } from 'react-i18next';

const Error404: React.FC = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);  // 언어 변경
  };

  return (
    <div className="App">
      <Header />

      <div className='error404'>
        <div className='error-not-found'>404</div>
        <div className='sorry'>{t('pageNotFound')}</div>
        <div className='sorry-not-found'>{t('wrongAddress')}</div>
        <div className='sorry-not-found'>{t('pageMovedOrDeleted')}</div>
      </div>
    

      <Footer />
    </div>
  );
};

export default Error404;
