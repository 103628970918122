import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ko'],
    fallbackLng: 'en',  // 언어 감지가 실패할 경우 기본 언어는 영어로 설정
    debug: true,  // 디버그 모드를 활성화하여 콘솔에서 정보를 확인 가능
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',  // 번역 파일 경로
    },
    detection: {
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],  // navigator 우선
      caches: ['cookie'],  // 언어 설정을 쿠키에 저장
    },
    interpolation: {
      escapeValue: false,  // React의 XSS 방지 처리
    }
  });

export default i18n;
