import defaultImage from '../imgs/default.png';
import React, { useEffect, useState } from 'react';
import './Rank.css';
import FlipMove from 'react-flip-move';
import { RouletteData, HpChange, HpUpdate, Participant } from './CommonInterface';

interface RankProps {
    rouletteData: RouletteData;
    currentTimeMs: number;
}

const Rank: React.FC<RankProps> = ({ rouletteData, currentTimeMs }) => {
    const [participants, setParticipants] = useState<Participant[]>([]);

    // 초기 HP 순서대로 정렬하는 함수
    useEffect(() => {
        const initializeRankings = () => {
            const sortedParticipants = [...rouletteData.participants].sort((a, b) => b.initialHp - a.initialHp);
            setParticipants(sortedParticipants); // 초기 HP 순서대로 참가자 배열 설정
        };

        initializeRankings(); // 초기 순위를 설정
    }, [rouletteData]);


    // HP 업데이트에 따라 순위를 재정렬하는 함수
    useEffect(() => {
        const applyHpUpdates = () => {
            const relevantHpChanges = rouletteData.hpChanges.filter(
                (change: HpChange) => change.timestampMs <= currentTimeMs
            );

            if (relevantHpChanges.length > 0) {
                let updatedParticipants = [...participants];

                relevantHpChanges.forEach((relevantHpChange) => {
                    updatedParticipants = updatedParticipants.map((participant) => {
                        const hpUpdate = relevantHpChange.hpUpdates.find(
                            (update: HpUpdate) => update.participantId === participant.participantId
                        );
                        return hpUpdate
                            ? { ...participant, initialHp: hpUpdate.currentHp }
                            : participant;
                    });
                });

                // HP에 따라 참가자 정렬
                const sortedParticipants = updatedParticipants.sort((a, b) => b.initialHp - a.initialHp);
                setParticipants(sortedParticipants); // 상태 업데이트
            }
        };

        applyHpUpdates();
    }, [currentTimeMs, rouletteData]); // participants를 종속성에서 제거

    // 임시 처리: 나중에 빼세요
    const tempProfile = defaultImage;

    return (
        <section className="rank-section">
                <FlipMove easing={"linear"}  className="scroll-container">
                    {participants.map((user, index) => (
                        <div
                            className="rank-item"
                            key={user.participantId}
                            data-id={user.participantId}
                            style={{ backgroundColor: user.color}}
                        >
                            <div className="ranks">
                                <div className="rank-number">{index + 1}</div>
                                <img src={/*user.profileUrl*/tempProfile} alt={user.name} className="rank-image" />
                                <div className="rank-name">{user.name}</div>
                            </div>
                            <div>
                                <div className="rank-percent">{user.initialHp}%</div>
                            </div>
                        </div>
                    ))}
                </FlipMove>
        </section>
    );
};

export default Rank;
