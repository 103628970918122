// src/structure/Footer.js

import React from 'react';
import './Footer.css'; // 필요한 CSS를 Footer.css에서 추가해주세요.
import facebookMark from '../imgs/facebook.png';
import instagramMark from '../imgs/instagram.png';
import twitterMark from '../imgs/twitter.png';
const Footer = () => {
  return (
    <footer className="footer">
      <div className="content">
        <div className="company-info">
        <p>&copy; 2024 <span>BS-Company</span> All Rights Reserved.</p>
        </div>

        <div className="social-media">
       
          <ul>
            <li>
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <img src={facebookMark} alt="Facebook" className="social-icon"/>
              </a>
            </li>
            <li>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                <img src={twitterMark} alt="Twitter" className="social-icon" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <img src={instagramMark} alt="Instagram" className="social-icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
