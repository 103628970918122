// src/structure/Header.js

import React,{useState,useEffect} from 'react';
import './Header.css'; // 필요한 CSS를 Header.css에서 추가해주세요.
import { getRouletteData } from '../service/get';
import { Link,useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // 다국어 지원을 위한 훅
const Header: React.FC = () => {
  const [title, setTitle] = useState('');
  const [time, setTime] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState(true);
  const { id } = useParams();
  const { t, i18n } = useTranslation(); // useTranslation 훅 사용
  const [togggleLang,setToggleLang ] = useState('ko');
  const [toggleLangWord, setToggleLangWord] = useState('한국어');
  const fetchUserData = async () => {
    try {
      const result = await getRouletteData(id);
      if (result) {
        setTitle(result.data.rouletteTitle);
        setName(result.data.creatorName);
        setTime(result.createDate);
      }
      setError(false);
    } catch (err) {
      setError(true);
      console.error('Error fetching data:', err);
    }
  };
  useEffect(()=>{
    fetchUserData();
      
      if(i18n.language ==='ko'){
        setToggleLangWord('English');
        setToggleLang('en');
      }else{
        setToggleLangWord('한국어');
        setToggleLang('ko');
      }
  },[])

  // 언어 변경 함수
  const changeLanguage = (lng: string) => {
    if (lng === 'ko') {
      setToggleLangWord('English');
      setToggleLang('en');
    } else {
      setToggleLangWord('한국어');
      setToggleLang('ko');
    }
    i18n.changeLanguage(lng); // 언어 변경
  };


  return (
    <header className="header">
   
      <span className='logo'>
          <span className='fontsize'><span className='big-letter'>그</span>쪽이 <span className='big-letter'>골</span>라주세요</span>
        </span>
      
       {!error &&(
         <span className='title-content'>
          <span className='title'>{title}</span>
          <span className='content'>{time}{t('atTime')}  <span style={{color:'#00FF62'}}>{name}</span>{t('rouletteCreated')}</span>
        </span>
       )}

      
        {/* 언어 변경 버튼 */}
      <div className='language-buttons'>

        <button className="toggle-button" onClick={() => changeLanguage(togggleLang)}>{toggleLangWord}</button>
      
      </div>
    </header>
  );
};

export default Header;
