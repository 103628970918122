import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../structure/Header';
import Footer from '../structure/Footer';
import Video from './Video';
import Rank from './Rank';
import './DashBoard.css';
import { getRouletteData } from "../service/get";
import { RouletteData } from "./CommonInterface";
import spinner from '../imgs/spinner.png';
import Errors from './Errors';

const SERVER_URL = 'http://geugol.site';
const Dashboard: React.FC = () => {
  const { id } = useParams();
  const videoRef = useRef<HTMLVideoElement | null>(null); // 비디오 엘리먼트를 참조하는 useRef
  const [disableAnimation, setDisableAnimation] = useState(false);
  const [rouletteData, setRouletteData] = useState<RouletteData>();
  const [timeMs, setTimeMs] = useState<number>(0); // 현재 비디오 시간
  const [video, setVideo] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
 // 순위를 초기 상태로 리셋하는 함수
 const resetRankings = () => {
  if (rouletteData) {
    const sortedParticipants = [...rouletteData.participants].sort((a, b) => b.initialHp - a.initialHp);
    setRouletteData({ ...rouletteData, participants: sortedParticipants });
    setTimeMs(0); // 비디오 시간을 초기화
  }
};
  // 비디오 컴포넌트에서 비디오의 시간을 추적하여 App 상태로 설정
  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime * 1000; // 비디오 현재 시간을 밀리초 단위로 변환
      setTimeMs(currentTime); // 현재 시간을 상태로 설정
    }
  };

  // 비디오가 끝났을 때 호출되는 함수
  const handleVideoEnd = (endTimeMs: number) => {
    setDisableAnimation(true);  // 애니메이션 비활성화
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await getRouletteData(id);
      setRouletteData(data.data);
      setVideo(SERVER_URL+data.videoPath);
      
    } catch (error) {
      setError(true);
      console.error('Error fetching user data:', error);
    }finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    console.log(`video
      

      `,video)
    if (video) {
      video.addEventListener('timeupdate', handleTimeUpdate);
    }

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      if (video) {
        video.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [video]);

  return (
      <div className="App">
          {error && (
            <Errors></Errors>
          )}
          { !error && !loading &&(
            <>
             <Header />
             <main className="main-content">
              <Video ref={videoRef} onVideoEnd={handleVideoEnd} video={video} onPlayFromStart={resetRankings}/> {/* forwardRef로 전달된 ref를 직접 사용합니다. */}
              {rouletteData && <Rank rouletteData={rouletteData} currentTimeMs={timeMs} />} {/* 현재 시간 전달 */}
              </main>
          <Footer />
            </>
          ) }
          {!error&&loading && (
            <div className="spinner-container">
              <img src={spinner} className="spinner" alt="Loading..." />
            </div>
         
          )}

      </div>
  );
};

export default Dashboard;
