import apiClient from './apiClient';

export const getRouletteData = async (uc: string): Promise<any> => {
    const url = `/dev/info?uc=${uc}`;
    let response = await apiClient.get(url); //Ul_hgy
    // let response = {
    //     "data": {
    //         "createDate": "2024-10-12T15:38:43.851349",
    //         "videoPath": "/media/Geugul_Video_20241012153843.mp4",
    //         "data": {
    //             "hpChanges": [
    //                 {
    //                     "hpUpdates": [
    //                         {
    //                             "hpChange": -5,
    //                             "currentHp": 95,
    //                             "participantId": "unique_id_3"
    //                         },
    //                         {
    //                             "hpChange": -7,
    //                             "currentHp": 93,
    //                             "participantId": "unique_id_5"
    //                         },
    //                         {
    //                             "hpChange": -3,
    //                             "currentHp": 97,
    //                             "participantId": "unique_id_7"
    //                         }
    //                     ],
    //                     "timestampMs": 300
    //                 },
    //                 {
    //                     "hpUpdates": [
    //                         {
    //                             "hpChange": -6,
    //                             "currentHp": 94,
    //                             "participantId": "unique_id_2"
    //                         },
    //                         {
    //                             "hpChange": -5,
    //                             "currentHp": 95,
    //                             "participantId": "unique_id_8"
    //                         },
    //                         {
    //                             "hpChange": -4,
    //                             "currentHp": 96,
    //                             "participantId": "unique_id_9"
    //                         }
    //                     ],
    //                     "timestampMs": 600
    //                 },
    //                 {
    //                     "hpUpdates": [
    //                         {
    //                             "hpChange": -8,
    //                             "currentHp": 92,
    //                             "participantId": "unique_id_1"
    //                         },
    //                         {
    //                             "hpChange": -5,
    //                             "currentHp": 95,
    //                             "participantId": "unique_id_4"
    //                         },
    //                         {
    //                             "hpChange": -7,
    //                             "currentHp": 93,
    //                             "participantId": "unique_id_6"
    //                         }
    //                     ],
    //                     "timestampMs": 900
    //                 }
    //             ],
    //             "creatorName": "전투 관리자",
    //             "participants": [
    //                 {
    //                     "name": "참가자 1",
    //                     "color": "#FF5733",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_1"
    //                 },
    //                 {
    //                     "name": "참가자 2",
    //                     "color": "#33FF57",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_2"
    //                 },
    //                 {
    //                     "name": "참가자 3",
    //                     "color": "#3357FF",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_3"
    //                 },
    //                 {
    //                     "name": "참가자 4",
    //                     "color": "#FF33A1",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_4"
    //                 },
    //                 {
    //                     "name": "참가자 5",
    //                     "color": "#FFA533",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_5"
    //                 },
    //                 {
    //                     "name": "참가자 6",
    //                     "color": "#A533FF",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_6"
    //                 },
    //                 {
    //                     "name": "참가자 7",
    //                     "color": "#FF5733",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_7"
    //                 },
    //                 {
    //                     "name": "참가자 8",
    //                     "color": "#33FFA5",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_8"
    //                 },
    //                 {
    //                     "name": "참가자 9",
    //                     "color": "#5733FF",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_9"
    //                 },
    //                 {
    //                     "name": "참가자 10",
    //                     "color": "#33FFDD",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_10"
    //                 },
    //                 {
    //                     "name": "참가자 11",
    //                     "color": "#FF3333",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_11"
    //                 },
    //                 {
    //                     "name": "참가자 12",
    //                     "color": "#33FF33",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_12"
    //                 },
    //                 {
    //                     "name": "참가자 13",
    //                     "color": "#3333FF",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_13"
    //                 },
    //                 {
    //                     "name": "참가자 14",
    //                     "color": "#FF33FF",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_14"
    //                 },
    //                 {
    //                     "name": "참가자 15",
    //                     "color": "#FFFF33",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_15"
    //                 },
    //                 {
    //                     "name": "참가자 16",
    //                     "color": "#33FFFF",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_16"
    //                 },
    //                 {
    //                     "name": "참가자 17",
    //                     "color": "#FFAA33",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_17"
    //                 },
    //                 {
    //                     "name": "참가자 18",
    //                     "color": "#FFAAFF",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_18"
    //                 },
    //                 {
    //                     "name": "참가자 19",
    //                     "color": "#AAFF33",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_19"
    //                 },
    //                 {
    //                     "name": "참가자 20",
    //                     "color": "#AA33FF",
    //                     "initialHp": 100,
    //                     "profileUrl": "url",
    //                     "participantId": "unique_id_20"
    //                 }
    //             ],
    //             "rouletteTitle": "격렬한 전투",
    //             "initialRankings": [
    //                 {
    //                     "rank": 1,
    //                     "participantId": "unique_id_1"
    //                 },
    //                 {
    //                     "rank": 2,
    //                     "participantId": "unique_id_2"
    //                 },
    //                 {
    //                     "rank": 3,
    //                     "participantId": "unique_id_3"
    //                 },
    //                 {
    //                     "rank": 4,
    //                     "participantId": "unique_id_4"
    //                 },
    //                 {
    //                     "rank": 5,
    //                     "participantId": "unique_id_5"
    //                 },
    //                 {
    //                     "rank": 6,
    //                     "participantId": "unique_id_6"
    //                 },
    //                 {
    //                     "rank": 7,
    //                     "participantId": "unique_id_7"
    //                 },
    //                 {
    //                     "rank": 8,
    //                     "participantId": "unique_id_8"
    //                 },
    //                 {
    //                     "rank": 9,
    //                     "participantId": "unique_id_9"
    //                 },
    //                 {
    //                     "rank": 10,
    //                     "participantId": "unique_id_10"
    //                 },
    //                 {
    //                     "rank": 11,
    //                     "participantId": "unique_id_11"
    //                 },
    //                 {
    //                     "rank": 12,
    //                     "participantId": "unique_id_12"
    //                 },
    //                 {
    //                     "rank": 13,
    //                     "participantId": "unique_id_13"
    //                 },
    //                 {
    //                     "rank": 14,
    //                     "participantId": "unique_id_14"
    //                 },
    //                 {
    //                     "rank": 15,
    //                     "participantId": "unique_id_15"
    //                 },
    //                 {
    //                     "rank": 16,
    //                     "participantId": "unique_id_16"
    //                 },
    //                 {
    //                     "rank": 17,
    //                     "participantId": "unique_id_17"
    //                 },
    //                 {
    //                     "rank": 18,
    //                     "participantId": "unique_id_18"
    //                 },
    //                 {
    //                     "rank": 19,
    //                     "participantId": "unique_id_19"
    //                 },
    //                 {
    //                     "rank": 20,
    //                     "participantId": "unique_id_20"
    //                 }
    //             ]
    //         }
    //     }
    // }
    
    console.log(response);
    return response.data;
};
