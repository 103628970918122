import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../structure/Header';
import Footer from '../structure/Footer';
import './DashBoard.css';

const Errors: React.FC = () => {

  const { t } = useTranslation(); // useTranslation 훅 사용
 // 언어 변경 함수


  return (
    <div className="App">
      <Header />

      <div className='error404'>
        <div className='error-not-found'>500</div>
        <div className='sorry'>{t('serverError')}</div> {/* 번역된 서버 오류 메시지 */}
        <div className='sorry-not-found'>{t('refreshPage')}</div> {/* 번역된 새로고침 요청 */}
        <div className='sorry-not-found'>{t('thankYou')}</div> {/* 번역된 감사 메시지 */}
      </div>

      <Footer />
    </div>
  );
};

export default Errors;
